<template>
  <b-card class="rounded-0">
    <LineChartGenerate :name="'Sipariş'" :data="chartToplam" :styles="styles" :height="200" />
  </b-card>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import LineChartGenerate from '@/components/chart/LineChartGenerate.vue';
export default defineComponent({
  components: { LineChartGenerate },
  props: {
    chartToplam: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const expo = {};
    expo.styles = ref({
      borderColor: '#00aaff',
      color: '#00aaff',
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
