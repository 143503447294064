<template>
  <b-card class="rounded-0">
    <DoughnutChartGenerate :data="chartToplam" :height="210" />
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import DoughnutChartGenerate from '@/components/chart/DoughnutChartGenerate.vue';
export default defineComponent({
  components: { DoughnutChartGenerate },
  props: {
    chartToplam: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
