<template>
  <div class="product-search">
    <b-form @submit.stop.prevent="$emit('handlerFilter', true)">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Başlama Tarihi">
            <flat-pickr
              v-model="start_date"
              class="form-control form-control-lg rounded-0 bg-white"
              :config="config"
              :events="config.events"
              @on-change="handlerOnChangeDate('start', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Bitiş Tarihi">
            <flat-pickr
              v-model="end_date"
              class="form-control form-control-lg rounded-0 bg-white"
              :config="config"
              :events="config.events"
              @on-change="handlerOnChangeDate('end', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Sıralama">
            <v-select
              v-model="filter.siralama"
              :options="siralamalar"
              :reduce="(sira) => sira.id"
              label="title"
              :clearable="true"
              placeholder="Vitrin..."
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-button type="submit" block variant="info" size="lg" class="rounded-0">
            <i class="fad fa-filter"></i> FİLTRE UYGULA
          </b-button>
        </b-col>
        <b-col cols="12" md="6">
          <b-button block variant="danger" size="lg" class="rounded-0" @click="$emit('filtered', false)">
            <i class="fad fa-window-close"></i> TEMİZLE
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { defineComponent, computed, ref, toRefs } from '@vue/composition-api';
export default defineComponent({
  components: { vSelect, flatPickr },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};

    const { filter } = toRefs(props);
    expo.start_date = ref(moment().format('DD.MM.YYYY'));
    expo.end_date = ref(moment().format('DD.MM.YYYY'));
    expo.config = ref({
      wrap: true,
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      mode: 'single',
      // minDate: 'today',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
    });

    expo.siralamalar = ref([
      { id: 'artan', title: 'Toplam Adet ( A-Z )' },
      { id: 'azalan', title: 'Toplam Adet ( Z-A )' },
    ]);

    expo.handlerOnChangeDate = (event, date) => {
      if (event == 'start') {
        filter.value.baslama_tarih = moment(String(date)).format('YYYY-MM-DD');
      } else {
        filter.value.bitis_tarih = moment(String(date)).format('YYYY-MM-DD');
      }
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
