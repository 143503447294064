<template>
  <div class="products">
    <div class="product-header" :class="{ active: filtered }">
      <Filtrele :filter="filter" @filtered="handlerTemizle" @handlerFilter="handlerFilter" />
    </div>
    <div class="product-body">
      <b-alert v-if="!rows.length" show variant="warning" class="text-center m-2">
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert>
      <div v-else>
        <b-row class="my-2">
          <b-col cols="12" md="8">
            <AylarChart :chartToplam="chartToplam" />
          </b-col>
          <b-col cols="12" md="4">
            <OdemeTipiChart :chartToplam="chartOdemeYontemiToplam" />
          </b-col>
        </b-row>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :pagination-options="{
            enabled: false,
            perPage: 1000,
            mode: 'pages',
          }"
          :search-options="{ enabled: false }"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'k_no'">
              <router-link
                :to="{
                  name: 'siparis-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                <span class="font-weight-bold d-block">
                  {{ props.row.k_no }}
                </span>
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'tarih'">
              <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 14px">
                {{ props.row.tarih | momentFull }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'uye'" class="d-flex flex-column">
              <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 14px">
                {{ props.row.uye_ad }} {{ props.row.uye_soyad }}
              </b-badge>
              <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
                {{ props.row.uye_e_mail }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'odeme_tipi'">
              <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 14px">
                {{
                  props.row.odeme_yontemi == 'kapida_odeme'
                    ? 'Kapıda Ödeme'
                    : props.row.odeme_yontemi == 'havale_eft'
                    ? 'Banka Havalesi'
                    : 'Kredi Kartı Ödeme'
                }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'tahsilat'">
              <b-badge
                :variant="props.row.tahsilat_durum == 1 ? 'success' : 'danger'"
                class="rounded-0 font-weight-light"
                style="font-size: 14px"
              >
                {{ props.row.tahsilat_durum == 1 ? 'Tahsil Edildi' : 'Tahsil Edilmedi' }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'durum'">
              <b-badge
                class="rounded-0 d-block font-weight-light"
                style="font-size: 14px"
                :style="{ background: props.row.siparis_drm_renk }"
              >
                {{ props.row.siparis_drm['tr'].baslik }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'fiyat'">
              <h5>
                <b-badge variant="success" class="rounded-0 font-weight-light d-block text-right">
                  {{ props.row.sepet_toplam.genel_toplam | cur }} {{ props.row.kur_turu }}
                </b-badge>
              </h5>
              <h5 v-if="props.row.kur_turu != props.row.secilen_kur">
                <b-badge variant="info" class="rounded-0 font-weight-light d-block text-right">
                  {{ props.row.kur_sepet_toplam.genel_toplam | cur }} {{ props.row.secilen_kur }}
                </b-badge>
              </h5>
            </span>

            <span v-else-if="props.column.field === 'action'" class="float-right px-1">
              <b-button-group size="sm" class="pb-25">
                <b-button
                  class="rounded-0"
                  variant="info"
                  v-b-tooltip.hover.top="'Siparis Detay'"
                  :to="{
                    name: 'siparis-detay',
                    params: { k_no: props.row.k_no },
                  }"
                >
                  <i class="fad fa-eye" />
                </b-button>
              </b-button-group>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import moment from 'moment';
import vSelect from 'vue-select';
import Filtrele from './component/Filtrele';
import { VueGoodTable } from 'vue-good-table';
import BTableCard from '@/components/cards/BTableCard.vue';
import { defineComponent, ref } from '@vue/composition-api';
import AylarChart from './component/AylarChart.vue';
import OdemeTipiChart from './component/OdemeTipiChart.vue';
export default defineComponent({
  components: {
    vSelect,
    BTableCard,
    VueGoodTable,
    BTableCard,
    Filtrele,
    AylarChart,
    OdemeTipiChart,
  },
  props: {
    filtered: [Boolean],
  },
  setup(props, context) {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.chartToplam = ref([]);
    expo.chartOdemeYontemiToplam = ref([]);
    expo.count = ref(0);
    expo.filter = ref({
      baslama_tarih: moment().format('YYYY-MM-DD'),
      bitis_tarih: moment().format('YYYY-MM-DD'),
      odeme_yontemi: null,
      tahsilat_durum: null,
      siparis_kapama: null,
      siparis_durum: null,
      siralama: 'artan', // artan, azalan
    });
    expo.count = ref(0);

    expo.columns = ref([
      {
        label: 'Siparis Numarası',
        field: 'k_no',
      },
      {
        label: 'Tarih',
        field: 'tarih',
      },
      {
        label: 'Uye',
        field: 'uye',
      },
      {
        label: 'Ödeme Tipi',
        field: 'odeme_tipi',
      },
      {
        label: 'Tahsilat',
        field: 'tahsilat',
      },
      {
        label: 'Durum',
        field: 'durum',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Fiyat',
        field: 'fiyat',
        width: '12%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
      },
    ]);

    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      await store.dispatch('siparisRaporuListele', expo.filter.value).then((res) => {
        if (res.data.success) {
          expo.rows.value = res.data.data;
          expo.chartToplam.value = res.data.chart;
          expo.chartOdemeYontemiToplam.value = res.data.chartOdemeYontemi;
          context.emit('total', expo.rows.value.length);
        }
      });
      context.emit('show', false);
    };
    handlerFetchAllData();

    expo.handlerFilter = () => {
      handlerFetchAllData();
      context.emit('filtered', false);
    };

    expo.handlerTemizle = () => {
      expo.filter.value = {
        baslama_tarih: moment().format('YYYY-MM-DD'),
        bitis_tarih: moment().format('YYYY-MM-DD'),
        siralama: 'artan', // artan, azalan
      };
      context.emit('filtered', false);
      handlerFetchAllData();
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
